import React from "react";
import Banner from "../../components/Banner";
import Services from "../../components/Services";
import About from "../../components/About";
import Blog from "../../components/Blog";
import Consultation from "../../components/Consultation";
import NewsLetter from "../../components/NewsLetter";
import Project from "../../components/Project";
import Team from "../../components/Team";
import Testimonial from "../../components/Testimonial";
import Layout from "../../layouts/Layout";

const Home = () => {
  return (
    <Layout header={1} footer={1}>
      <Banner />
      <Services />
      <About />
      <Team />
      <Project />
      <Testimonial />
      <Consultation />
      <Blog />
      <NewsLetter />
    </Layout>
  );
};

export default Home;
