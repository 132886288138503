import React from "react";
import { Link } from "react-router-dom";

const BannerOne = () => {
  return (
    <section className="banner-area banner-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-img wow fadeInLeft" data-wow-delay=".4s">
              <img src="/images/banner/success_img.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-content">
              <span className="sub-title wow fadeInUp" data-wow-delay=".2s">
                Apa yang <strong>menakjubkan</strong> disini?
              </span>
              <h2 className="title wow fadeInUp" data-wow-delay=".4s">
                Kembangkan Bisnis Dengan Layanan Kami
              </h2>
              <a
                href="#contact"
                className="btn wow fadeInUp"
                data-wow-delay=".6s"
              >
                Kontak Kami <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-shape-wrap">
        <img src="/images/banner/banner_shape01.png" alt="" />
        <img
          src="/images/banner/banner_shape02.png"
          alt=""
          className="animationFramesOne"
        />
        <img
          src="/images/banner/banner_shape03.png"
          alt=""
          className="contactSwimmer"
        />
        <img src="/images/banner/banner_shape04.png" alt="" className="rotateme" />
        <img
          src="/images/banner/banner_shape05.png"
          alt=""
          className="animation1"
        />
        <img
          src="/images/banner/banner_shape06.png"
          alt=""
          className="ribbonRotate"
        />
        <img
          src="/images/banner/banner_shape07.png"
          alt=""
          className="float-bob-x"
        />
      </div>
    </section>
  );
};

export default BannerOne;
