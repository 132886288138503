import React from "react";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="cart-img">
                  <img src="/images/footer/" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="copyright-text text-end">
                  <p>© 2024 <Link to="https://doyan-it.my.id">Doyan IT</Link>. All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
