import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section id="about" className="about-area">
      <div className="container custom-container">
        <div className="about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-46 order-0 order-lg-2">
              <div className="about-img text-end">
                <img src="/images/about/about_img.jpg" alt="" />
              </div>
            </div>
            <div className="col-54">
              <div className="about-content">
                <div className="section-title mb-25">
                  <span className="sub-title">Tentang Perusahaan</span>
                  <h2 className="title">
                    <span className="text-primary">Doyan IT</span> Adalah Agensi Desain & Branding
                  </h2>
                </div>
                <p>
                Doyan-it adalah sebuah website yang memberikan pengalaman di dunia teknologi tentang teknologi
                terbaru dan juga tutorial tutorial seputar teknologi . Doyan-it juga menyediakan jasa pembuatan website dan desain logo , poster dll .
                </p>
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <img src="/images/about/about_icon01.png" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Penciptaan Konsep</h4>
                      <p>
                      Diskusikan Konsep Website atau Aplikasi Seluler Anda dengan tim kami.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="/images/about/about_icon02.png" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Gambar Sketsa</h4>
                      <p>
                      Tim kami akan membuat sketsa model situs web atau aplikasi seluler Anda.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="/images/about/about_icon03.png" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Final Design</h4>
                      <p>
                      Tahap terakhir, tim kami akan membuat situs web atau aplikasi seluler Anda.
                      </p>
                    </div>
                  </li>
                </ul>
                
                <div className="about-content-bottom">
                  <span>Agensi Kreatif Adalah Desain Layanan Penuh</span>
                  <div className="read-more-btn">
                    <Link to="https://wa.me/6282141648401" className="btn">
                      Hubungi Kami <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
