import React from "react";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <section id="team" className="team-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="team-img-wrap">
              <img
                src="/images/team/team_img_shape.png"
                alt=""
                className="img-shape"
              />
              <img src="/images/team/team_img01.png" alt="" className="img-one" />
              <img src="/images/team/team_img02.png" alt="" className="img-two" />
              <img
                src="/images/team/team_img03.png"
                alt=""
                className="img-three"
              />
              <img src="/images/team/team_img04.png" alt="" className="img-four" />
              <img src="/images/team/team_img05.png" alt="" className="img-five" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-content">
              <div className="section-title mb-25">
                <span className="sub-title">Tim Kami</span>
                <h2 className="title">
                 Aspek Penting dari Kreativitas adalah Tidak Takut Gagal
                </h2>
              </div>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p> */}
              <a href="#belum-ada-aksi" className="btn">
                Temui Tim Kami<span></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="team-shape-wrap">
        <img
          src="/images/team/team_shape01.png"
          alt=""
          className="ribbonRotate"
        />
        <img
          src="/images/team/team_shape02.png"
          alt=""
          className="float-bob-x"
        />
      </div>
    </section>
  );
};

export default Team;
