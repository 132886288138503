import React from "react";
import BlogItem from "./BlogItem";

const Blog = () => {
  const blog_area_items = [
    {
      src: "/images/blog/create-login-pendaftaran-php.png",
      tag: "Program",
      title: "Langkah-Langkah Membuat Form Login ",
      author_img: "/images/blog/blog_avatar01.png",
      author_name: "Doyan IT",
    },
    {
      src: "/images/blog/blog_img02.jpg",
      tag: "Teknologi",
      title: "Open Source Intelligence",
      author_img: "/images/blog/blog_avatar02.png",
      author_name: "Doyan IT",
    },
    {
      src: "/images/blog/blog_img03.jpg",
      tag: "Merek",
      title: "Tutorial JavaScript Bagi Pemula",
      author_img: "/images/blog/blog_avatar03.png",
      author_name: "Doyan IT",
    },
  ];

  return (
    <section id="blog" className="blog-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Blog Kami</span>
              <h2 className="title">Berita & Pembaruan</h2>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p> */}
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {blog_area_items.map((x, index) => (
            <div key={index} className="col-lg-4 col-md-6">
              <BlogItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
