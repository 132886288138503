import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Preloader from "../components/Preloader";
import ScrollTop from "../components/ScrollTop";

const Layout = ({ children, header = 1, footer, className, mainClassName }) => {
  return (
    <>
      <Preloader />
      <ScrollTop />

      <div className={className}>
        {header === 1 && <Header />}

        <main className={mainClassName}>{children}</main>

        {footer === 1 && <Footer />}
      </div>
    </>
  );
};

export default Layout;
