import React from "react";
import SlickSlider from "./SlickSlider";
import TestimonialItem from "./TestimonialItem";
import $ from "jquery";
import { doAnimations } from "../lib/helpers";
import cn from "classnames";

const Testimonial = () => {
  const slick_settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: function (currentSlide, nextSlide) {
      var $animatingElements = $(
        '.single-slider[data-slick-index="' + nextSlide + '"]'
      ).find("[data-animation]");
      doAnimations($animatingElements);
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slider_items = [
    {
      title: "Asep",
      designation: "Client",
      desc: `''Saya sangat puas dengan aplikasi mobile yang dibuat oleh Doyan IT. Tim mereka sangat kreatif dan mampu membuat aplikasi yang sesuai dengan kebutuhan saya. Aplikasi ini sangat membantu saya dalam mengelola bisnis dan meningkatkan produktivitas saya. Terima kasih, Doyan IT! “`,
      src: 1,
    },
    {
      title: "Vivy",
      designation: "Client",
      desc: `''Kami sangat puas dengan jasa pembuatan website yang diberikan oleh Doyan IT. Tim mereka sangat profesional dan mampu memahami kebutuhan kami dengan baik. Hasilnya, website kami terlihat menarik dan informatif, serta mudah dinavigasi. Terima kasih“`,
      src: 2,
    },
    // {
    //   title: "Client Gacor 2",
    //   designation: "Client",
    //   desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “`,
    //   src: 3,
    // },
    // {
    //   title: "Client Gacor 3",
    //   designation: "Client",
    //   desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “`,
    //   src: 1,
    // },
    // {
    //   title: "Client Gacor 4",
    //   designation: "Client",
    //   desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “`,
    //   src: 4,
    // },
    // {
    //   title: "Client Gacor 5",
    //   designation: "Client",
    //   desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “`,
    //   src: 1,
    // },
    // {
    //   title: "Client Gacor 6",
    //   designation: "Client",
    //   desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “`,
    //   src: 3,
    // },
  ];

  return (
    <section id="testimonial" className="testimonial-area pt-110 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Testimoni</span>
              <h2 className="title">Apa Kata Pelanggan Kami</h2>
            </div>
          </div>
        </div>

        <div className="testimonial-item-wrap">
          <div className="row testimonial-active">
            <SlickSlider settings={slick_settings}>
              {slider_items.map((x, index) => (
                <div
                  key={index}
                  className={cn(
                    "col-lg-3",
                    index % 2 === 0 && "testimonial-item-wrap-item-even"
                  )}
                >
                  <TestimonialItem item={x} />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
