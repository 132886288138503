import React from "react";

const ConsultationForm = () => {
  return (
    <div className="consultation-form-wrap">
      <h4 className="title">Konsultasi Gratis</h4>
      <form action="https://wa.me/6282143632580">
        <div className="form-grp">
          <input type="text" placeholder="Nama" />
        </div>
        <div className="form-grp">
          <input type="email" placeholder="Alamat Email" />
        </div>
        {/* <div className="form-grp">
          <input type="text" placeholder="No Telepon" />
        </div> */}
        <div className="form-grp">
          <select
            id="shortBy"
            name="select"
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">Pilihan</option>
            <option>Admin | Doyan IT</option>
            <option>Admin | Support</option>
          </select>
        </div>
        <button className="btn" type="submit">
          Konsultasi
        </button>
      </form>
    </div>
  );
};

export default ConsultationForm;
