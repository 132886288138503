import React from "react";
import ServicesItem from "./ServicesItem";

const Services = () => {
  const services_area_list = [
    {
      title: "Website Design",
      desc: "Menyediakan jasa pembuatan website dengan desain Modern, Mobile Friendly, & SEO Friendly.",
      url: "#services",
      delay_time: 6,
    },
    {
      title: "Mobile Design",
      desc: "Menyediakan jasa pembuatan aplikasi yang ringan, mudah digunakan, dan informatif.",
      url: "#services",
      delay_time: 2,
    },
    {
      title: "Digital Marketing",
      desc: "Menyediakan layanan pemasaran digital seperti beriklan di Google dan Media Sosial.",
      url: "#services",
      delay_time: 4,
    },
    {
      title: "SEO Optimization",
      desc: "Menyediakan jasa optimasi SEO pada website, sehingga mendapatkan posisi terbaik di pencarian Google.",
      url: "#services",
      delay_time: 8,
    },
  ];

  return (
    <section id="services" className="services-area pt-35 pb-95">
      <div className="container">
        <div className="row justify-content-center">
          {services_area_list.map((item, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
              <ServicesItem index={index} item={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
